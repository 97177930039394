import React, { type FC } from 'react';
import { TextLink } from '@sbt-web/ui';
import { ToggleDetails } from '@client/components/Search/Elements/ToggleDetails';
import classes from './Footer.module.scss';
import type { InternalLink } from './types';

interface FooterProps {
  links: InternalLink[];
}

const Footer: FC<FooterProps> = ({ links }) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <section data-google-interstitial="false">
      <ToggleDetails summary={<h2>Ricerche consigliate</h2>}>
        <div className={classes['links']}>
          {links.map((link) => (
            <TextLink
              key={link.href}
              href={link.href}
              weight="book"
              size="sm"
              themed={false}
              classes={[classes['link']]}
            >
              {link.label}
            </TextLink>
          ))}
        </div>
      </ToggleDetails>
    </section>
  );
};

export default Footer;
