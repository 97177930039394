import { AdTypes, SearchOrders } from '@sbt-web/network/types';

export const adTypesToSlugMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'vendita',
  [AdTypes.Rent]: 'affitto',
  [AdTypes.Seek]: 'cerco',
  [AdTypes.VacationRent]: 'affitto-vacanze',
  [AdTypes.GiveAway]: 'regalo',
};

export const slugToAdTypeMap: Record<string, AdTypes> = {
  vendita: AdTypes.Sell,
  affitto: AdTypes.Rent,
  cerco: AdTypes.Seek,
  'affitto-vacanze': AdTypes.VacationRent,
  regalo: AdTypes.GiveAway,
};

export const adTypesLabelsMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'In vendita',
  [AdTypes.Seek]: 'Cercasi',
  [AdTypes.Rent]: 'In affitto',
  [AdTypes.VacationRent]: 'In affitto per vacanze',
  [AdTypes.GiveAway]: 'In regalo',
};

export const adTypesShortLabelsMap: Record<AdTypes, string> = {
  [AdTypes.Sell]: 'Vendita',
  [AdTypes.Seek]: 'Cercasi',
  [AdTypes.Rent]: 'Affitto',
  [AdTypes.VacationRent]: 'Affitto',
  [AdTypes.GiveAway]: 'Regalo',
};

export const ordersFullLabelsMap: Record<SearchOrders, string> = {
  relevance: 'Dal più rilevante',
  datedesc: 'Dal più recente',
  priceasc: 'Dal meno caro',
  pricedesc: 'Dal più caro',
  yearasc: 'Per anno crescente',
  yeardesc: 'Per anno decrescente',
  mileageasc: 'Con meno chilometri',
  mileagedesc: 'Con più chilometri',
};

export const QSOLabel = 'Cerca solo nel titolo';

export const shippableOnlyLabel = 'Solo annunci con spedizione disponibile';
export const urgentLabel = 'Solo annunci urgenti';

export enum AdvertiserType {
  Private = 0,
  ImpresaPiu = 1,
  Company = 2,
}
