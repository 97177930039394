import { mapActualQueryStringToSEO } from '@client/components/SEO/helpers/automotive';
import type { SearchState } from '@reducers/search';
import type { AdItem } from '@sbt-web/network/types';
import { isEmpty } from '@sbt-web/utils';
import { queryHasRedirectableParameters, rewriteQuery } from './pretty-urls';
import { adTypesToSlugMap } from './values';
import { SPECIAL_FILTERS } from './getFiltersStore';
import type { FiltersConfigState } from '@reducers/filtersConfig';

interface PageInfo {
  path: string;
  url: string;
}

/**
 * Converts a SearchState into a relative URL for the listing it represents.
 * @param search The SearchState to map
 * @returns the relative path and the path plus query string of the new listing
 */
function mapSearchToPublicURL(
  search: SearchState,
  filtersConfigState: FiltersConfigState
): PageInfo {
  const { geo, category, adType } = search;

  // Models the entire path of the URL after the hostname
  let path: string;

  const type = adTypesToSlugMap[adType];

  if (search.radiusSearch != null) {
    path = `/annunci-locali/${type}/${category.friendly}/`;
  } else {
    const region = search.searchNearRegions
      ? `${geo.region.friendlyName}-vicino`
      : geo.region.friendlyName;
    const city = geo.city ? `${geo.city.friendlyName}/` : '';
    const town = geo.town ? `${geo.town.friendlyName}/` : '';

    /**
     * There is always a trailing slash: either city and town are both empty,
     * or the slashes they include are present.
     */
    path = `/annunci-${region}/${type}/${category.friendly}/${city}${town}`;
  }

  // Build the URLQueryParams from the SearchState
  let queryObject = getParamsFromSearch(search, filtersConfigState);

  // If it has params which should be in the path
  if (queryHasRedirectableParameters(category.id, queryObject)) {
    // Extract them, rewriting the path and the query.
    const { path: searchPath, rewrittenQuery } = rewriteQuery(
      category.id,
      queryObject
    );

    path = path + searchPath;
    queryObject = rewrittenQuery;
  }

  return {
    path,
    url: `${path}${buildQueryString(queryObject)}`,
  };
}

/**
 * Maps a SearchState to the URL path used for internal (and client-side)
 * navigation.
 * @param search The SearchState to use
 * @returns The string to pass to the internal router
 */
function mapSearchToInternalURL(
  search: SearchState,
  filtersConfigState: FiltersConfigState
): string {
  const { geo, searchNearRegions, category, adType } = search;

  const type = adTypesToSlugMap[adType];

  const query = getParamsFromSearch(search, filtersConfigState);

  query.set('type', type);
  query.set('category', category.friendly);

  if (search.radiusSearch != null) {
    query.set('region', 'locali');
  } else {
    const region = geo.region.friendlyName;
    const city = geo.city ? `${geo.city.friendlyName}` : '';
    const town = geo.town ? `${geo.town.friendlyName}` : '';

    query.set('region', region);

    if (city) {
      query.set('city', city);
    }

    if (town) {
      query.set('town', town);
    }
  }

  if (searchNearRegions) {
    query.set('near', 'true');
  }

  const searchString = buildQueryString(query);

  return `/listing${searchString}`;
}

enum BreadcrumbLevels {
  region,
  category,
  city,
  town,
}

function mapDetailToPageInfo(
  item: AdItem,
  geoLevel?: BreadcrumbLevels
): PageInfo {
  const { geo, category } = item;
  const adType = item.type.key;

  const type = adTypesToSlugMap[adType];
  const region = geo.region.friendlyName;
  const city = geo.city ? `${geo.city.friendlyName}/` : '';
  const town = geo.town ? `${geo.town.friendlyName}/` : '';

  let path = `/annunci-${region}/${type}/`;
  if (geoLevel === BreadcrumbLevels.region) {
    return {
      path,
      url: path,
    };
  }

  path = `${path}${category.friendlyName}/`;
  if (geoLevel === BreadcrumbLevels.category) {
    return {
      path,
      url: path,
    };
  }
  path = `${path}${city}`;
  if (geoLevel === BreadcrumbLevels.city) {
    return {
      path,
      url: path,
    };
  }
  path = `${path}${town}`;
  return {
    path,
    url: path,
  };
}

/**
 * Converts a SearchState into a query string
 * (URLSearchParams in memory).
 * The names used here must align with the ListingQuery in
 * src/shared/routes.ts
 */
function getParamsFromSearch(
  search: SearchState,
  filtersConfig: FiltersConfigState
): URLSearchParams {
  const currentConfig = filtersConfig.filtersStore;

  const queryObj: Record<string, string> = {};

  // Map the names of the SearchState to those needed for the query.
  if (search.query) {
    queryObj.q = search.query;
  }

  if (search.qso) {
    queryObj.qso = 'true';
  }

  if (search.includeShippableOnly) {
    queryObj.shp = 'true';
  }

  if (search.includeUrgent) {
    queryObj.urg = 'true';
  }

  if (search.radiusSearch != null) {
    queryObj.rad = search.radiusSearch.radiusMeters.toString();
    queryObj.lat = search.radiusSearch.center.lat.toString();
    queryObj.lon = search.radiusSearch.center.lng.toString();
  }

  if (search.orderIndex) {
    queryObj.order = search.category.orders[search.orderIndex];
  }

  if (search.page > 1) {
    queryObj.o = search.page.toString();
  }

  if (search.geo.zone) {
    queryObj.z = search.geo.zone.id;
  }

  if (!isEmpty(search.filters) && currentConfig) {
    currentConfig.sparseFilters.forEach((filter) => {
      // * special filters are treated already singularly and should not be included in the query anymore
      if (SPECIAL_FILTERS.has(filter.uri)) return;
      if (search.filters[filter.queryString]) {
        queryObj[mapActualQueryStringToSEO(filter.queryString)] =
          search.filters[filter.queryString];
      }
    });
  }

  return new URLSearchParams(queryObj);
}

/**
 * Converts URLSearchParams into a string with plusses replacing the spaces,
 * and with a leading question mark.
 * @param queryParams The params to convert into a string.
 * @returns The stringified version of the params, including the question mark.
 */
function buildQueryString(queryParams: URLSearchParams): string {
  let queryString = queryParams.toString().replace(/%20/g, '+');
  if (queryString.length > 0) {
    queryString = `?${queryString}`;
  }

  return queryString;
}

export {
  mapSearchToPublicURL,
  mapDetailToPageInfo,
  buildQueryString,
  mapSearchToInternalURL,
};
