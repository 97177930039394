import { getDataLayer, getOrCreatePulse } from '@tools/tracking/utils';
import { getAdId } from '@sbt-web/utils';
import { DLAd } from '@client/bridges/DataLayer';
import {
  transformCategory,
  transformPostalAddress,
  transformPublisher,
} from '@tools/tracking/transformer';

const getData = (urn: string, action: 'Save' | 'Unsave') => {
  const rawData = getDataLayer();
  const rawAd = urn
    ? (rawData.ads.find((ad: DLAd) => ad.urn === urn) ?? rawData[0])
    : rawData[0];
  return {
    type: action,
    object: {
      id: rawAd.urn,
      type: 'ClassifiedAd',
      name: rawAd.subject,
      publisher: transformPublisher(rawAd.advertiser),
      category: transformCategory(rawAd.category),
      location: transformPostalAddress(rawAd.geo),
    },
    target: {
      id: '0000000000000000',
      type: 'Listing',
    },
    origin: {
      type: 'Listing',
      id: `sdrn:subito:listing:${window.document.URL}`,
      name: rawData.page.name,
    },
  };
};

export const onEngage = (urn: string) => {
  const rawData = getDataLayer();
  const rawAd = urn
    ? (rawData.ads.find((ad: DLAd) => ad.urn === urn) ?? rawData[0])
    : rawData[0];
  getOrCreatePulse()?.queueEvent({
    type: 'Engagement',
    object: {
      id: 'save-ad-button',
      type: 'UIElement',
      elementType: 'button',
    },
    target: {
      type: 'ClassifiedAd',
      id: `${getAdId(urn)}`,
      name: document.title,
      category: transformCategory(rawAd.category),
    },
    origin: {
      type: 'Listing',
      id: `sdrn:subito:listing:${window.document.URL}`,
      name: rawData.page.name,
    },
  });
};

export const onSave = (urn: string) => {
  getOrCreatePulse()?.queueEvent(getData(urn, 'Save'));
};

export const onUnsave = (urn: string) => {
  getOrCreatePulse()?.queueEvent(getData(urn, 'Unsave'));
};
