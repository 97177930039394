import { CategoryID } from '@sbt-web/network/types';
import { TuttaItalia } from '@sbt-web/networking';
import { SearchState } from '@reducers/search';

const GEOBOOST_TOGGLE: string = 'subito.web.adv.geoboost';
const GEOBOOST_CATEGORIES: CategoryID[] = [CategoryID.Auto];

const isSearchEligibleForGeoboost = (search: SearchState): boolean => {
  const isRegionEligible =
    search.geo.region.friendlyName !== TuttaItalia.friendlyName;
  const isCategoryEligible = GEOBOOST_CATEGORIES.includes(search.category.id);

  return isRegionEligible && isCategoryEligible;
};

export { GEOBOOST_TOGGLE, GEOBOOST_CATEGORIES, isSearchEligibleForGeoboost };
