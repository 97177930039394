import { CategoryID } from '@sbt-web/network/types';

const BEST_BUY_TOGGLE = 'subito.web.listing.best-buy-smoke-test';
const BEST_BUY_CATEGORIES = [CategoryID.Auto, CategoryID.Moto];
const BEST_BUY_LISTING_POSITION = 10;

const DISMISS_STORAGE_KEY = 'best_buy_dismiss';
const COUNT_STORAGE_KEY = 'best_buy_count';
const LAST_SEARCH_STORAGE_KEY = 'best_buy_on';

export {
  BEST_BUY_CATEGORIES,
  BEST_BUY_LISTING_POSITION,
  BEST_BUY_TOGGLE,
  DISMISS_STORAGE_KEY,
  COUNT_STORAGE_KEY,
  LAST_SEARCH_STORAGE_KEY,
};
