import { DataLayer } from '@client/bridges/DataLayer';
import { FilterURI } from '@sbt-web/network/types';
import { slugify } from '@sbt-web/utils';
import transformAdType from '@tools/tracking/transformer/AdTypeTransformer';
import transformLocality from '@tools/tracking/transformer/LocalityTransformer';
import {
  transformToGeolocations,
  transformToLocality,
  transformToLocations,
} from '@tools/tracking/transformer/RadiusLocalityTransformer';

export default function transform(
  searchData: DataLayer['search'],
  adsData: { length: number }
): { filters: Record<string, string | boolean | unknown>; numFilters: number } {
  const filters = {
    query: searchData.query || '',
    numResults: adsData.length.toString(),
    region:
      searchData.geo != null
        ? slugify(searchData.geo.region?.name as string)
        : slugify(searchData.radiusSearch?.addressToTrack?.state ?? ''),
    locality:
      searchData.geo != null
        ? transformLocality(searchData.geo)
        : transformToLocality(searchData.radiusSearch),
    locations:
      searchData.radiusSearch != null
        ? [transformToLocations(searchData.radiusSearch)]
        : undefined,
    geolocations:
      searchData.radiusSearch != null
        ? [transformToGeolocations(searchData.radiusSearch)]
        : undefined,
    adType: transformAdType(searchData.adType),
    maxPrice: getFilterNameByKey(searchData.filters, '/price/max').replace(
      /\./g,
      ''
    ),
    minPrice: getFilterNameByKey(searchData.filters, '/price/min').replace(
      /\./g,
      ''
    ),
    maxRooms: getFilterNameByKey(searchData.filters, '/room/max'),
    minRooms: getFilterNameByKey(searchData.filters, '/room/min'),
    maxYear: getFilterNameByKey(searchData.filters, '/year/max'),
    minYear: getFilterNameByKey(searchData.filters, '/year/min'),
    maxMileage: getFilterNameByKey(searchData.filters, '/mileage/max'),
    minMileage: getFilterNameByKey(searchData.filters, '/mileage/min'),
    maxSurface: getFilterNameByKey(searchData.filters, '/size/max'),
    minSurface: getFilterNameByKey(searchData.filters, '/size/min'),
    newOrUsed: getCondition(searchData.filters),
    carBrand: getFilterNameByKey(searchData.filters, '/car/brand'),
    carModel: getFilterNameByKey(searchData.filters, '/car/model'),
    carType: getFilterNameByKey(searchData.filters, '/car_type'),
    transmission: getFilterNameByKey(searchData.filters, '/gearbox'),
    doors: getFilterNameByKey(searchData.filters, '/doors'),
    minSeats: getFilterNameByKey(searchData.filters, '/seats/min'),
    maxSeats: getFilterNameByKey(searchData.filters, '/seats/max'),
    fuelType: getFilterNameByKey(searchData.filters, '/fuel'),
    delivery: checkBooleanFilter(searchData.filters, FilterURI.ItemShippable)
      ? 'true'
      : '',
    urgent: checkBooleanFilter(searchData.filters, FilterURI.ItemUrgent)
      ? true
      : '',
    searchInDescription: !checkBooleanFilter(
      searchData.filters,
      FilterURI.QuerySubjectOnly
    ),
    sorting: getPulseSorting(searchData),
  };

  return {
    filters: removeEmptyProperties(filters),
    numFilters: countFilters(searchData.filters),
  };
}

export function countFilters(filters: Record<string, unknown>): number {
  return Object.keys(filters).filter((key) => key.startsWith('/')).length;
}

export function getFilterNameByKey(
  filters: Record<string, { name?: string }>,
  key: string
): string {
  return filters[key]?.name?.replace(/, /g, ';') || '';
}

export function checkBooleanFilter(
  filters: Record<string, DataLayer['search']['filters'][string]>,
  key: string
): boolean {
  return key in filters;
}

// eslint-disable-next-line
export function getPulseSorting(rawData: Record<string, any>): string {
  if (!('sort' in rawData && 'id' in rawData.sort)) {
    return '';
  }
  const mapping = {
    datedesc: 'PublishedTimeDesc',
    priceasc: 'PriceAsc',
    pricedesc: 'PriceDesc',
    mileageasc: 'MilageAsc',
    mileagedesc: 'MileageDesc',
    yearasc: 'YearAsc',
    yeardesc: 'YearDesc',
  };

  return mapping[rawData.sort.id as keyof typeof mapping] || 'Other';
}

export function removeEmptyProperties(
  filters: Record<
    string,
    string | boolean | Record<string, unknown> | Array<unknown> | undefined
  >
): Record<string, string | unknown> {
  const newObj:
    | string
    | boolean
    | Record<string, unknown>
    | unknown[]
    | undefined = {};
  Object.keys(filters).forEach((prop) => {
    if (filters[prop] !== '') {
      newObj[prop] = filters[prop];
    }
  });
  return newObj;
}

function getCondition(filters: Record<string, { name: string }>): string {
  const conditionFilters = [
    '/item_condition',
    '/building_condition',
    '/vehicle_status',
  ];

  for (let index = 0; index < conditionFilters.length; index++) {
    const key = conditionFilters[index];
    if (key in filters) {
      return getFilterNameByKey(filters, key);
    }
  }

  return '';
}
