import { CSSProperties } from 'react';
import { CategoryID } from '@sbt-web/network/types';

const GAM_DESKTOP_STYLE: CSSProperties = {
  minHeight: '150px',
  alignItems: 'center',
  display: 'flex',
};
const ADSENSE_DESKTOP_STYLE: CSSProperties = {
  minHeight: '202px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};
const ADSENSE_MSITE_STYLE: CSSProperties = {
  minHeight: '150px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
};
const GAM_MSITE_STYLE: CSSProperties = {
  // 200px is to obtain the best result with the native placements
  minHeight: '200px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexWrap: 'wrap',
};

enum MsiteGAMIds {
  NATIVE1 = 'reclame-listing-top-native',
  NATIVE2 = 'li-gpt-msite-listing-2',
  BOX1 = 'reclame-listing-top',
  BOX2 = 'li-gpt-msite-listing-3',
  BOX3 = 'reclame-listing-bottom',
  BOX4 = 'li-gpt-msite-listing-4',
  BOX5 = 'li-gpt-msite-listing-5',
  FALLBACK1 = 'listing-fallback-1',
  FALLBACKMIDDLE1 = 'listing-fallback-middle',
}

enum DesktopGAMIds {
  NATIVE3 = 'apn_native_top_tag3',
  NATIVE4 = 'apn_native_top_tag4',
  NATIVE5 = 'apn_native_top_tag5',
  NATIVE6 = 'apn_native_top_tag6',
  FALLBACK1 = 'listing-fallback-1',
  FALLBACKMIDDLE1 = 'listing-fallback-middle',
  FALLBACKMIDDLE2 = 'listing-fallback-middle2',
  FALLBACKMIDDLE3 = 'listing-fallback-middle3',
}

const CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE: CategoryID[] = [
  CategoryID.Elettronica,
  CategoryID.Informatica,
  CategoryID.ConsoleVideogiochi,
  CategoryID.AudioVideo,
  CategoryID.Fotografia,
  CategoryID.Telefonia,

  CategoryID.CasaPersona,
  CategoryID.ArredamentoCasalinghi,
  CategoryID.Elettrodomestici,
  CategoryID.GiardinoFaiDaTe,
  CategoryID.AbbigliamentoAccessori,
  CategoryID.TuttoBambini,

  CategoryID.SportsHobby,
  CategoryID.Animali,
  CategoryID.AccessoriAnimali,
  CategoryID.MusicaFilm,
  CategoryID.LibriRiviste,
  CategoryID.StrumentiMusicali,
  CategoryID.Sports,
  CategoryID.Biciclette,
  CategoryID.Collezionismo,
  CategoryID.AltriAnnunci,
];

export {
  GAM_DESKTOP_STYLE,
  ADSENSE_DESKTOP_STYLE,
  ADSENSE_MSITE_STYLE,
  GAM_MSITE_STYLE,
  CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE,
  MsiteGAMIds,
  DesktopGAMIds,
};
