import { combineReducers } from 'redux';
import { env, type EnvState } from './env';
import { items, type ItemsState } from './items';
import { search, type SearchState } from './search';
import { system, type SystemState } from './system';
import { filtersConfig, type FiltersConfigState } from './filtersConfig';

export const reducer = combineReducers<RootState>({
  system,
  search,
  items,
  env,
  filtersConfig,
});

export interface RootState {
  system: SystemState;
  search: SearchState;
  items: ItemsState;
  env: EnvState;
  filtersConfig: FiltersConfigState;
}
