import type { SubitoAction } from '@reducers/common';
import type { Reducer } from 'redux';
import type { FiltersStore } from '@tools/search/getFiltersStore';

export interface FiltersConfigState {
  filtersStore: FiltersStore | null;
  loading: boolean;
}

enum Types {
  UPDATE_FILTERS_STORE_REQUEST = 'update filters store request',
  UPDATE_FILTERS_STORE_SUCCESS = 'update filters store success',
}

export const defaultState: FiltersConfigState = {
  filtersStore: null,
  loading: false,
};

export const filtersConfig: Reducer<
  FiltersConfigState,
  FiltersConfigActions
> = (state = defaultState, action): FiltersConfigState => {
  switch (action.type) {
    case Types.UPDATE_FILTERS_STORE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case Types.UPDATE_FILTERS_STORE_SUCCESS:
      return {
        filtersStore: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export function updateFiltersConfigRequest(): SubitoAction<Types.UPDATE_FILTERS_STORE_REQUEST> {
  return {
    type: Types.UPDATE_FILTERS_STORE_REQUEST,
    payload: undefined,
  };
}

export function updateFiltersConfigSuccess(
  payload: FiltersStore | null
): SubitoAction<Types.UPDATE_FILTERS_STORE_SUCCESS, FiltersStore | null> {
  return { type: Types.UPDATE_FILTERS_STORE_SUCCESS, payload };
}

type FiltersConfigActions =
  | ReturnType<typeof updateFiltersConfigRequest>
  | ReturnType<typeof updateFiltersConfigSuccess>;
