import { Feature } from '@client/components/Detail/FeatureList';
import { FeatureURI, ItemFeature, ItemFeatures } from '@sbt-web/network/types';

const translateLabelToURI: Record<string, string> = {
  Marca: 'brand',
  Modello: 'model',
  Versione: 'version',
};

export function isPack(f: ItemFeature): boolean {
  return f.type === 'pack';
}

export function mapPackToItemFeatures(
  features: ItemFeatures
): Record<string, ItemFeature> {
  const retFeatures = { ...features };
  Object.values(retFeatures).forEach((value) => {
    if (isPack(value)) {
      value.values.forEach((f) => {
        const subURI = (f.label && translateLabelToURI[f.label]) ?? '';
        const newURI = `${value.uri}/${subURI}` as FeatureURI;
        retFeatures[newURI] = {
          uri: newURI,
          type: 'list',
          label: f['label'] || '',
          values: [{ key: f.key, value: f.value }],
        };
      });
    }
  });
  return retFeatures;
}

export function mapPackFeatures(features: ItemFeature[]): Feature[] {
  return features
    .filter(isPack)
    .map((f) =>
      f.values.map((pf) => ({
        label: pf.label,
        value: pf.value,
        uri: f.uri,
      }))
    )
    .reduce((acc, f) => acc.concat(f), []);
}
