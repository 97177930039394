import type { DLAd } from '@client/bridges/DataLayer';
import transformCategory from '@tools/tracking/transformer/CategoryTransformer';
import transformPublisher, {
  Publisher,
} from '@tools/tracking/transformer/PublisherTransformer';
import type { AdItem } from '@sbt-web/network/types';

export interface PulseClassifiedAd {
  id: string;
  publisher: Publisher;
  type: 'ClassifiedAd';
  category: string;
  name: string;
}

export default function transform(
  rawAds: DLAd[] | AdItem[],
  rawPage: { name: string }
): PulseClassifiedAd[] {
  return rawAds.map((ad) => ({
    id: ad.urn,
    publisher: transformPublisher(ad.advertiser),
    type: 'ClassifiedAd' as const,
    category: transformCategory(ad.category),
    name: `${rawPage.name}`,
  }));
}
