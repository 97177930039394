export default new Map<string, string>([
  ['000002', 'fiat-argenta'],
  ['000003', 'bravo-brava'],
  ['000004', 'fiat-campagnola'],
  ['000005', 'cinquecento'],
  ['000006', 'fiat-coupe'],
  ['000007', 'croma'],
  ['000009', 'doblo'],
  ['000014', 'duna'],
  ['000015', 'fiorino'],
  ['000016', 'fiat-grande-punto'],
  ['000017', 'idea'],
  ['000018', 'marea'],
  ['000021', 'multipla'],
  ['000023', 'palio'],
  ['000024', 'panda'],
  ['000026', 'fiat-punto'],
  ['000029', 'regata'],
  ['000030', 'ritmo'],
  ['000033', 'sedici'],
  ['000034', 'seicento'],
  ['000035', 'fiat-stilo'],
  ['000038', 'tempra'],
  ['000040', 'ulysse'],
  ['000042', 'uno'],
  ['000044', '126'],
  ['000045', '127'],
  ['000046', '131'],
  ['000047', '132'],
  ['000048', '147-127-rustica'],
  ['000049', 'fiat-600'],
  ['000051', 'challenge-stradale'],
  ['000052', 'dino-208-gt-4'],
  ['000053', 'dino-308-gt-4'],
  ['000054', 'enzo'],
  ['000055', 'f355'],
  ['000056', 'f40'],
  ['000057', 'f430'],
  ['000058', 'f50'],
  ['000059', 'mondial'],
  ['000060', 'testarossa-512-tr'],
  ['000061', '208-308-328-gto'],
  ['000062', '348'],
  ['000063', '360'],
  ['000064', '412'],
  ['000065', '456'],
  ['000066', '512-bb'],
  ['000068', '550'],
  ['000069', '575m'],
  ['000070', '599'],
  ['000071', '612'],
  ['000072', 'logan'],
  ['000073', 'renault-12'],
  ['000074', 'agila'],
  ['000075', 'ascona'],
  ['000076', 'astra'],
  ['000079', 'calibra'],
  ['000084', 'corsa'],
  ['000087', 'frontera'],
  ['000088', 'kadett-e'],
  ['000089', 'opel-manta'],
  ['000090', 'meriva'],
  ['000093', 'omega'],
  ['000095', 'senator-monza'],
  ['000096', 'signum'],
  ['000097', 'sintra'],
  ['000098', 'speedster'],
  ['000099', 'tigra'],
  ['000101', 'vectra'],
  ['000105', 'zafira'],
  ['000107', 'boxster'],
  ['000109', 'carrera-gt'],
  ['000110', 'cayenne'],
  ['000111', 'cayman'],
  ['000117', '924-944'],
  ['000118', '928'],
  ['000119', '959'],
  ['000120', '968'],
  ['000121', 'classe-a'],
  ['000123', 'classe-b'],
  ['000124', 'classe-c'],
  ['000129', 'classe-clk'],
  ['000132', 'classe-e'],
  ['000134', 'classe-g'],
  ['000136', 'classe-gl'],
  ['000137', 'classe-m'],
  ['000140', 'classe-s'],
  ['000142', 'classe-sl'],
  ['000144', 'classe-slk'],
  ['000148', 'serie-s'],
  ['000151', 'serie-sec'],
  ['000153', 'serie-sl'],
  ['000156', 'serie-200-320'],
  ['000160', 'slr'],
  ['000163', 'vaneo'],
  ['000167', '190'],
  ['000168', 'k'],
  ['000169', 'beta-berlina'],
  ['000170', 'beta-montecarlo'],
  ['000171', 'betacoupespiderhpe'],
  ['000172', 'dedra'],
  ['000173', 'delta'],
  ['000174', 'delta-s4'],
  ['000176', 'gamma'],
  ['000177', 'lybra'],
  ['000178', 'musa'],
  ['000179', 'phedra'],
  ['000180', 'prisma'],
  ['000181', 'rallye-37'],
  ['000182', 'thema'],
  ['000183', 'thesis'],
  ['000184', 'y'],
  ['000185', 'ypsilon'],
  ['000186', 'lancia-z'],
  ['000187', 'a112'],
  ['000188', 'y10'],
  ['000189', 'cougar'],
  ['000192', 'escort'],
  ['000194', 'fiesta'],
  ['000198', 'focus'],
  ['000199', 'focus-focus-c-max'],
  ['000200', 'fusion'],
  ['000201', 'galaxy'],
  ['000202', 'ford-ka'],
  ['000203', 'mondeo'],
  ['000206', 'orion'],
  ['000207', 'puma-1997-2002'],
  ['000209', 'scorpio'],
  ['000210', 'sierra'],
  ['000211', 'taunus'],
  ['000216', 'cabrio-supercabrio'],
  ['000217', 'freeclimber'],
  ['000219', '4x4'],
  ['000220', 'alhambra'],
  ['000221', 'altea'],
  ['000222', 'arosa'],
  ['000223', 'cordoba'],
  ['000225', 'fura'],
  ['000226', 'ibiza'],
  ['000230', 'seat-leon'],
  ['000232', 'malaga'],
  ['000233', 'marbella'],
  ['000234', 'ronda'],
  ['000236', 'toledo'],
  ['000239', 'alpine'],
  ['000240', 'avantime'],
  ['000241', 'clio'],
  ['000244', 'espace'],
  ['000248', 'kangoo'],
  ['000250', 'laguna'],
  ['000253', 'megane'],
  ['000259', 'modus'],
  ['000260', 'safrane'],
  ['000263', 'renault-spider'],
  ['000266', 'twingo'],
  ['000267', 'vel-satis'],
  ['000269', '11'],
  ['000270', '18'],
  ['000271', '19'],
  ['000273', '21'],
  ['000274', 'renault-25'],
  ['000275', '4'],
  ['000276', '5'],
  ['000277', '9'],
  ['000278', 'arnage'],
  ['000279', 'berlina-modello'],
  ['000281', 'continental-azure'],
  ['000282', 'bentley-corniche'],
  ['000283', 't-t2'],
  ['000287', 'lagonda'],
  ['000288', 'virage-v8-vantage'],
  ['000289', 'v12'],
  ['000290', 'v8-vantage'],
  ['000291', 'allante'],
  ['000292', 'bls'],
  ['000293', 'cts'],
  ['000294', 'eldorado-seville'],
  ['000295', 'escalade'],
  ['000296', 'fleetwood'],
  ['000297', 'seville'],
  ['000298', 'srx'],
  ['000299', 'sts'],
  ['000300', 'xlr'],
  ['000301', 'maestro'],
  ['000302', 'metro'],
  ['000303', 'austin-rover-mini'],
  ['000304', 'montego'],
  ['000305', 'park-avenue'],
  ['000306', 'alfa-6'],
  ['000307', 'alfa-90'],
  ['000308', 'alfasud'],
  ['000309', 'alfetta'],
  ['000310', 'alfetta-gt-gtv'],
  ['000311', 'arna'],
  ['000312', 'brera'],
  ['000313', 'giulietta'],
  ['000314', 'gtv-spider'],
  ['000315', 'alfa-romeo-gt'],
  ['000316', 'alfa-romeo-spider'],
  ['000317', 'sprint'],
  ['000318', 'sz-rz'],
  ['000319', '145'],
  ['000320', '146'],
  ['000321', '147'],
  ['000323', '155'],
  ['000324', '156'],
  ['000326', '159'],
  ['000327', '164'],
  ['000328', '166'],
  ['000330', '33'],
  ['000331', 'alfa-romeo-75'],
  ['000332', 'camaro'],
  ['000335', 'epica'],
  ['000336', 'chevrolet-evanda'],
  ['000337', 'chevrolet-kalos'],
  ['000338', 'chevrolet-lacetti'],
  ['000339', 'chevrolet-matiz'],
  ['000342', 's-blazer'],
  ['000344', 'chevrolet-tacuma'],
  ['000345', 'tahoe'],
  ['000346', 'trailblazer'],
  ['000349', 'serie-3'],
  ['000354', 'serie-5'],
  ['000357', 'serie-6'],
  ['000359', 'serie-7'],
  ['000365', 'z1'],
  ['000366', 'z3'],
  ['000367', 'z4'],
  ['000368', 'z8'],
  ['000369', 'camargue'],
  ['000370', 'corniche'],
  ['000373', 'phantom'],
  ['000376', 'silver-shadow'],
  ['000377', 'silver-spirit-spur'],
  ['000378', 'silver-wraith'],
  ['000379', 'crossfire'],
  ['000380', 'neon'],
  ['000381', 'new-yorker'],
  ['000382', 'pt-cruiser'],
  ['000383', 'sebring'],
  ['000384', 'stratus'],
  ['000385', 'chrysler-viper'],
  ['000386', 'vision'],
  ['000387', 'voy-gvoyager'],
  ['000390', '300-c'],
  ['000391', '300-m'],
  ['000392', 'axel'],
  ['000393', 'ax'],
  ['000394', 'citroen-berlingo'],
  ['000395', 'bx'],
  ['000396', 'cx'],
  ['000397', 'c1'],
  ['000399', 'c2'],
  ['000400', 'c3'],
  ['000401', 'c4'],
  ['000402', 'c5'],
  ['000404', 'c6'],
  ['000405', 'c8'],
  ['000406', 'citroen-evasion'],
  ['000407', 'gs-gsa'],
  ['000412', 'ln-lna'],
  ['000413', 'mehari'],
  ['000414', 'saxo'],
  ['000415', 'visa'],
  ['000416', 'xantia'],
  ['000417', 'xsara'],
  ['000418', 'xm'],
  ['000419', 'zx'],
  ['000420', '2cv'],
  ['000421', 'fabia'],
  ['000422', 'favorit-forman'],
  ['000423', 'felicia'],
  ['000425', 'octavia-4-4'],
  ['000427', 'skoda-serie-100'],
  ['000428', 'superb'],
  ['000429', 'spagna-patrol'],
  ['000430', 'c70'],
  ['000431', 'serie-300'],
  ['000433', 'serie-400'],
  ['000435', 'serie-700'],
  ['000436', 'serie-800'],
  ['000438', 'serie-900'],
  ['000439', 's40'],
  ['000440', 's60'],
  ['000441', 's80'],
  ['000443', 'v50'],
  ['000444', 'v70-xc70'],
  ['000445', 'xc90'],
  ['000446', '240'],
  ['000447', 'bora'],
  ['000452', 'corrado'],
  ['000453', 'eos'],
  ['000454', 'fox'],
  ['000455', 'golf'],
  ['000460', 'jetta'],
  ['000462', 'lupo'],
  ['000464', 'maggiolino'],
  ['000466', 'new-beetle'],
  ['000467', 'passat'],
  ['000470', 'phaeton'],
  ['000471', 'polo'],
  ['000474', 'scirocco'],
  ['000475', 'sharan'],
  ['000477', 'touareg'],
  ['000478', 'touran'],
  ['000481', 'vento'],
  ['000482', 'avensis'],
  ['000484', 'aygo'],
  ['000485', 'camry'],
  ['000487', 'carina-e'],
  ['000488', 'celica'],
  ['000491', 'corolla'],
  ['000494', 'corolla-sw-tercel'],
  ['000499', 'land-cruiser'],
  ['000501', 'lexus-ls'],
  ['000502', 'mr2'],
  ['000504', 'paseo'],
  ['000505', 'picnic'],
  ['000506', 'previa'],
  ['000508', 'prius'],
  ['000510', 'rav4'],
  ['000513', 'supra'],
  ['000515', 'tercel'],
  ['000516', 'yaris'],
  ['000518', '4-runner-hilux'],
  ['000520', 'charade'],
  ['000521', 'copen'],
  ['000522', 'cuore'],
  ['000524', 'feroza'],
  ['000525', 'move'],
  ['000526', 'rocky'],
  ['000527', 'sirion'],
  ['000529', 'terios'],
  ['000531', 'yrv'],
  ['000532', 'super-eight'],
  ['000533', 'moretti-panda'],
  ['000534', 'moretti-uno'],
  ['000535', 's-type'],
  ['000547', 'club-650-11'],
  ['000548', 'deauville'],
  ['000549', 'guara'],
  ['000550', 'longchamp'],
  ['000551', 'pantera'],
  ['000552', 'maserati-coupe'],
  ['000553', 'ghibli'],
  ['000554', 'gransport'],
  ['000555', 'maserati-gt'],
  ['000556', 'quattroporte'],
  ['000559', 'shamal'],
  ['000560', 'spyder'],
  ['000561', 'elba'],
  ['000562', 'koral'],
  ['000563', 'mille'],
  ['000564', 'innocenti-mini'],
  ['000566', 'small-500-990'],
  ['000567', '990'],
  ['000568', 'accent'],
  ['000571', 'atos'],
  ['000572', 'coupe-modello'],
  ['000574', 'elantra'],
  ['000575', 'galloper'],
  ['000576', 'getz'],
  ['000577', 'hyundai-h1'],
  ['000579', 'lantra'],
  ['000580', 'matrix'],
  ['000581', 'pony-scoupe'],
  ['000582', 'santa-fe'],
  ['000584', 'santamo'],
  ['000585', 'sonica'],
  ['000588', 'sonica-sonata'],
  ['000589', 'stellar'],
  ['000590', 'terracan'],
  ['000591', 'trajet'],
  ['000592', 'tucson'],
  ['000593', 'xg30'],
  ['000594', 'polonez'],
  ['000595', 'elan'],
  ['000596', 'elise'],
  ['000597', 'esprit'],
  ['000598', 'exige'],
  ['000599', 'lamborghini-countach'],
  ['000600', 'diablo'],
  ['000601', 'gallardo'],
  ['000602', 'jalpa'],
  ['000603', 'lm-002'],
  ['000604', 'murcielago'],
  ['000605', 'morgan'],
  ['000606', 'firebird'],
  ['000607', 'trans-sport'],
  ['000608', 'defender'],
  ['000609', 'discovery'],
  ['000612', 'freelander'],
  ['000613', 'range-rover'],
  ['000614', 'range-rover-sport'],
  ['000616', 'cherokee'],
  ['000618', 'commander'],
  ['000619', 'grand-cherokee'],
  ['000621', 'wrangler'],
  ['000623', 'demio'],
  ['000624', 'mazda2'],
  ['000625', 'mazda3'],
  ['000626', 'mazda5'],
  ['000627', 'mazda6'],
  ['000628', 'mpv'],
  ['000630', 'mx-3'],
  ['000631', 'mx-5'],
  ['000634', 'premacy'],
  ['000635', 'rx-7'],
  ['000636', 'rx-8'],
  ['000637', 'tribute'],
  ['000638', 'xedos-6'],
  ['000639', 'xedos-9'],
  ['000640', '121'],
  ['000642', '323'],
  ['000645', '626'],
  ['000652', 'piazza'],
  ['000653', 'trooper'],
  ['000654', 'accord'],
  ['000659', 'civic'],
  ['000663', 'concerto'],
  ['000664', 'cr-v'],
  ['000666', 'fr-v'],
  ['000667', 'hr-v'],
  ['000668', 'integra'],
  ['000670', 'legend'],
  ['000671', 'logo'],
  ['000672', 'nsx'],
  ['000673', 'prelude'],
  ['000675', 'shuttle-odyssey'],
  ['000676', 'stream'],
  ['000677', 's2000'],
  ['000678', 'carisma'],
  ['000679', 'colt'],
  ['000684', 'eclipse'],
  ['000686', 'grandis'],
  ['000687', 'lancer'],
  ['000691', 'l200'],
  ['000694', 'outlander'],
  ['000695', 'pajero'],
  ['000697', 'pajero-pinin'],
  ['000698', 'pajero-sport'],
  ['000699', 'space-gear'],
  ['000700', 'space-runner'],
  ['000702', 'space-star'],
  ['000703', 'space-wagon'],
  ['000705', '3000-gt'],
  ['000706', 'almera'],
  ['000711', 'bluebird-1983-90'],
  ['000722', 'micra'],
  ['000725', 'murano'],
  ['000727', 'note'],
  ['000728', 'pathfinder'],
  ['000729', 'patrol'],
  ['000731', 'patrol-safari'],
  ['000735', 'primera'],
  ['000739', 'serena'],
  ['000740', 'sunny'],
  ['000744', 'vanette'],
  ['000746', 'x-trail'],
  ['000747', '200-sx'],
  ['000748', '300-zx'],
  ['000754', 'ranch'],
  ['000755', '1007'],
  ['000756', '104'],
  ['000757', '106'],
  ['000758', '107'],
  ['000759', '205'],
  ['000760', '206'],
  ['000761', '207'],
  ['000762', '305'],
  ['000763', '306'],
  ['000764', '307'],
  ['000765', '309'],
  ['000766', '405'],
  ['000767', '406'],
  ['000768', '407'],
  ['000769', '505'],
  ['000770', '604'],
  ['000771', '605'],
  ['000772', '607'],
  ['000773', '806'],
  ['000774', '807'],
  ['000775', '44994'],
  ['000777', '45055'],
  ['000779', '9-7x'],
  ['000780', '900'],
  ['000782', '9000'],
  ['000783', 'rover-maestro'],
  ['000784', 'rover-mini'],
  ['000785', 'rover-montego'],
  ['000794', 'streetwise'],
  ['000795', 'sd1'],
  ['000796', '25'],
  ['000797', '45'],
  ['000798', '75'],
  ['000799', 'e-mini-van'],
  ['000800', 'forester'],
  ['000801', 'impreza'],
  ['000803', 'justy'],
  ['000806', 'l'],
  ['000808', 'legacy'],
  ['000811', 'm80'],
  ['000812', 'outback'],
  ['000814', 'svx'],
  ['000815', 'vivio'],
  ['000816', 'xt'],
  ['000817', 'alto'],
  ['000819', 'baleno'],
  ['000820', 'carry-every'],
  ['000821', 'grand-vitara'],
  ['000823', 'ignis'],
  ['000825', 'jimny'],
  ['000826', 'liana'],
  ['000828', 'swift'],
  ['000830', 'sj400-samurai'],
  ['000831', 'sx4'],
  ['000832', 'vitara-sidekick'],
  ['000833', 'wagon-r'],
  ['000835', 'b3'],
  ['000843', 'audi-a2'],
  ['000854', 'coupe-quattro'],
  ['000855', 'q7'],
  ['000856', 'tt'],
  ['000859', 'el-volkswagen-sedan'],
  ['000860', 'serie-10'],
  ['000861', 'serie-240'],
  ['000862', 'donkervoort-super-eight'],
  ['000863', 'serie-g'],
  ['000864', 'tavria'],
  ['000865', 'serie-24-31'],
  ['000866', 'alter'],
  ['000867', 'umm-4x4'],
  ['000868', 'tigr'],
  ['000869', '469'],
  ['000870', 'chimaera-cerb-griff'],
  ['000871', 's'],
  ['000872', 'sagaris'],
  ['000873', 'tamora'],
  ['000874', 'tuscan'],
  ['000875', 't350'],
  ['000876', 'boxer-90'],
  ['000877', 'kallista'],
  ['000878', 'sulkycar'],
  ['000879', 'sulkydea-ydea'],
  ['000880', 'ambra'],
  ['000881', 'be-two'],
  ['000882', 'nova'],
  ['000883', 'x-too'],
  ['000886', 'viper'],
  ['000887', 'seven'],
  ['000888', 'super-seven'],
  ['000889', 'cobra'],
  ['000891', 'horizon'],
  ['000892', 'samba'],
  ['000893', 'solara'],
  ['000894', 'a721'],
  ['000895', 'a741'],
  ['000896', 'scouty'],
  ['000897', '300-400'],
  ['000898', 'aixam-500'],
  ['000899', 'abaca'],
  ['000900', 'albizia'],
  ['000901', 'titane'],
  ['000902', 'niva'],
  ['000903', 'samara'],
  ['000904', 'lada-serie-100'],
  ['000905', 'serie-124-125'],
  ['000906', 'magnum'],
  ['000907', 'gtc'],
  ['000908', 'lm'],
  ['000910', 'mantis'],
  ['000912', 'venturi'],
  ['000914', 'espero'],
  ['000915', 'daewoo-evanda'],
  ['000916', 'kalos'],
  ['000917', 'lacetti'],
  ['000918', 'lanos'],
  ['000919', 'leganza'],
  ['000921', 'daewoo-matiz'],
  ['000922', 'nexia'],
  ['000923', 'daewoo-nubira'],
  ['000925', 'tacuma'],
  ['000927', 'scimitar'],
  ['000930', 'cj'],
  ['000931', 'goa'],
  ['000932', 'serie-s-berlina'],
  ['000933', 'gorgona'],
  ['000935', 'carnival'],
  ['000936', 'cerato'],
  ['000937', 'clarus'],
  ['000938', 'joice'],
  ['000940', 'magentis'],
  ['000941', 'opirus'],
  ['000944', 'pride'],
  ['000945', 'kia-rio'],
  ['000948', 'shuma'],
  ['000949', 'sorento'],
  ['000950', 'sportage'],
  ['000952', 'ecology-lyra'],
  ['000955', 'virgo'],
  ['000956', 'iato'],
  ['000958', 'maruti-800'],
  ['000959', 'yugo-koral'],
  ['000960', 'shevero'],
  ['000964', 'rascal'],
  ['000965', 'ydea'],
  ['000966', 'al500'],
  ['000967', 'm500'],
  ['000969', 'pk500'],
  ['000971', 'family'],
  ['000972', 'korando'],
  ['000974', 'kyron'],
  ['000976', 'rexton'],
  ['000977', 'rodius'],
  ['000978', 'off-4wd-serie-10'],
  ['000979', 'axel-elettrica'],
  ['000980', 'ligier'],
  ['000981', 'marbella-elettrica'],
  ['000983', 'noble-m14'],
  ['000984', 'torpedo-m14'],
  ['000985', 'm400'],
  ['000986', 'passo'],
  ['000987', 'rocsta'],
  ['000988', 'bellier-asso'],
  ['000989', 'divane'],
  ['000990', 'opale'],
  ['000991', 'sturdy'],
  ['000992', 'vx'],
  ['000993', 'eb-110'],
  ['000994', 'barooder'],
  ['000995', 'media'],
  ['000996', 'speedino'],
  ['000997', 'chatenet-stella'],
  ['000998', 'symbol'],
  ['000999', 'spazia'],
  ['001000', 'elettrico'],
  ['001002', 'mgf'],
  ['001003', 'tf'],
  ['001004', 'zr'],
  ['001005', 'zs'],
  ['001006', 'zt'],
  ['001007', 'micron'],
  ['001008', 'gs'],
  ['001010', 'is'],
  ['001012', 'ls'],
  ['001013', 'rx'],
  ['001015', 'lexus-sc'],
  ['001016', 'moke'],
  ['001017', 'indica'],
  ['001018', 'indigo'],
  ['001020', 'tata-safari'],
  ['001021', 'tata-sport'],
  ['001022', 'p8'],
  ['001023', 'mini-mini'],
  ['001029', 'agora'],
  ['001030', 'spacia'],
  ['001031', 'bingo'],
  ['001032', 'c1db'],
  ['001033', 'c1dm'],
  ['001034', 'tasso-domino'],
  ['001035', 'hola'],
  ['001036', 'king'],
  ['001037', 'td'],
  ['001038', 'th'],
  ['001039', 'tasso-t2'],
  ['001040', 'tasso-t3'],
  ['001041', 'top-500'],
  ['001042', 'amica'],
  ['001043', 'eke'],
  ['001044', 'fun-tech'],
  ['001046', 'secma-sc'],
  ['001047', 'isigo'],
  ['001048', 'zonda'],
  ['001049', 'jadim'],
  ['001054', 'ferruccio'],
  ['001055', 'ginevra'],
  ['001056', 'helektra'],
  ['001057', 'jaster'],
  ['001058', 'mangusta'],
  ['001059', 'el'],
  ['001061', 'e2'],
  ['001062', 'e4'],
  ['001063', 'four'],
  ['001064', 'two'],
  ['001065', 'electric-mistry'],
  ['001066', 'gipsy-500'],
  ['001070', 'maranello-4cycle'],
  ['001109', 'maybach'],
  ['001119', 'open'],
  ['001120', 'hummer-h1'],
  ['001121', 'hummer-h2'],
  ['001122', 'h3'],
  ['001123', 'netta'],
  ['001124', 'corvette-c6'],
  ['001127', 'green-company-943'],
  ['001129', 'green-company-963'],
  ['001130', 'green-company-967'],
  ['001133', 'italcar-t2'],
  ['001134', 't3'],
  ['001135', 'giottiline-ginko'],
  ['001137', 'b9-tribeca'],
  ['001139', 'europa-s'],
  ['001140', 'azure'],
  ['001141', 'captiva'],
  ['001142', 'trevis'],
  ['001143', 'caliber'],
  ['001144', 's-max'],
  ['001147', 'x-pro'],
  ['001148', 'classe-cl'],
  ['001149', 'qashqai'],
  ['001150', 's7'],
  ['001151', 'ps-300'],
  ['001153', 'roomster'],
  ['001154', 'c30'],
  ['001155', 'antara'],
  ['001156', 'r8'],
  ['001158', 'italcar-domino'],
  ['001159', 'compass'],
  ['001160', 'pocket'],
  ['001161', 'safari'],
  ['001162', 'meta-sport'],
  ['001163', 'top'],
  ['001164', 'actyon'],
  ['001165', 'b5'],
  ['001166', 'continental'],
  ['001167', 'barchetta'],
  ['001168', 'escort-orion'],
  ['001169', 'jazz'],
  ['001170', 'x-type'],
  ['001171', 'xj'],
  ['001175', 'maxima-qx'],
  ['001176', 'patrol-gr'],
  ['001177', 'terrano-ii'],
  ['001181', '901-911-912'],
  ['001183', 'sseraph-pward'],
  ['001185', 'forfour'],
  ['001186', 'fortwo'],
  ['001187', 'samurai'],
  ['001189', 'multivan'],
  ['001190', 'serie-40'],
  ['001191', 'serie-70'],
  ['001192', 'serie-90'],
  ['001195', 'materia'],
  ['001199', 'crossline'],
  ['001201', 'dr-dr-5'],
  ['001202', 'bravo'],
  ['001205', 'ceed'],
  ['001206', 'auris'],
  ['001207', 'opel-gt'],
  ['001208', 'b6'],
  ['001210', 'c-crosser'],
  ['001211', 'c-max'],
  ['001212', '4007'],
  ['001213', 'cx-7'],
  ['001214', 'girasole'],
  ['001215', 'v70'],
  ['001216', 'granturismo'],
  ['001218', 'xc70'],
  ['001219', '308'],
  ['001220', 'fiat-500'],
  ['001221', 'fiat-x1-9'],
  ['001226', 'd3'],
  ['001227', 'b10-d10'],
  ['001230', 'x1-9'],
  ['001231', 'nubira'],
  ['001232', 'dr-3'],
  ['001239', 'almera-almera-tino'],
  ['001242', 'nitro'],
  ['001243', 'i30'],
  ['001244', 'grande-punto'],
  ['001245', 'xf'],
  ['001246', 'xk'],
  ['001247', 'db'],
  ['001248', 'patriot'],
  ['001249', 'mc'],
  ['001250', 'sherpa'],
  ['001251', 'tiguan'],
  ['001252', 'corvette'],
  ['001254', 'serie-8'],
  ['001255', 'x3'],
  ['001256', 'x5'],
  ['001257', 'nemo'],
  ['001258', 'bipper'],
  ['001259', 'giotti-victoria-ginko'],
  ['001260', 'ceo'],
  ['001261', 'hhr'],
  ['001262', 'partner'],
  ['001263', 'x6'],
  ['001264', 'avenger'],
  ['001265', 'koleos'],
  ['001266', 'brooklands'],
  ['001267', 'splash'],
  ['001268', 'gt-r'],
  ['001270', 'i10'],
  ['001274', 'roadster'],
  ['001277', 'city-coupe-cabrio'],
  ['001278', 'classe-clc'],
  ['001279', 'kuga'],
  ['001281', 'city'],
  ['001282', 'roadline'],
  ['001283', 'aveo'],
  ['001284', 'sandero'],
  ['001285', 'journey'],
  ['001287', 'xc60'],
  ['001288', 'mito'],
  ['001289', '500'],
  ['001290', 'classe-glk'],
  ['001291', 'q5'],
  ['001293', 'massif'],
  ['001294', 'campagnola'],
  ['001296', 'ex'],
  ['001297', 'fx'],
  ['001298', 'g'],
  ['001300', 'california'],
  ['001301', 'qubo'],
  ['001302', 'insignia'],
  ['001303', 'iq'],
  ['001304', 'allroad'],
  ['001305', 'street'],
  ['001306', 'i20'],
  ['001307', 'aloes'],
  ['001308', 'exeo'],
  ['001309', 'cruze'],
  ['001313', 'soul'],
  ['001314', 'panamera'],
  ['001315', 'pixo'],
  ['001316', 'a4-allroad'],
  ['001317', 'c3-picasso'],
  ['001318', 'urban-cruiser'],
  ['001319', '3008'],
  ['001320', 'insight'],
  ['001321', 'yeti'],
  ['001322', 'verso'],
  ['001323', 'x1'],
  ['001324', 'evora'],
  ['001325', 'grancabrio'],
  ['001326', 'nv200'],
  ['001327', '5008'],
  ['001330', 'fiat-punto-evo'],
  ['001331', 'dr-1'],
  ['001333', 'sls'],
  ['001334', 'spark'],
  ['001335', 'ds3'],
  ['001339', 'cube'],
  ['001340', 'venga'],
  ['001341', 'duster'],
  ['001343', 'ix35'],
  ['001346', 'rcz'],
  ['001347', 'ghost'],
  ['001348', 'a1'],
  ['001350', 'asx'],
  ['001351', 'abarth-punto-evo'],
  ['001352', 'rapide'],
  ['001353', 'cr-z'],
  ['001354', 'juke'],
  ['001356', 'wind'],
  ['001357', 'c-zero'],
  ['001358', 'a7'],
  ['001359', 'hover-5'],
  ['001360', 'm'],
  ['001361', 'miniauto'],
  ['001362', 'jade-2'],
  ['001365', 'wrx'],
  ['001366', 'tesla-roadster'],
  ['001367', 'v60'],
  ['001368', 'mulsanne'],
  ['001369', 'sulky'],
  ['001370', '508'],
  ['001371', 'm10'],
  ['001372', 'ix20'],
  ['001373', 'kerry'],
  ['001374', 'sonique'],
  ['001375', 'ion'],
  ['001376', 'cygnet'],
  ['001377', 'orlando'],
  ['001378', 'ds4'],
  ['001379', 'genesis'],
  ['001381', 'ct'],
  ['001382', 'i-miev'],
  ['001383', 'trezia'],
  ['001384', 'verso-s'],
  ['001388', 'crossover'],
  ['001389', 'gto'],
  ['001390', 'atom-3'],
  ['001391', 'virage'],
  ['001392', 'q3'],
  ['001401', 'm110'],
  ['001402', 'volt'],
  ['001403', 'ds5'],
  ['001405', 'ff'],
  ['001406', 'freemont'],
  ['001409', 'karma'],
  ['001410', 'c-max-business'],
  ['001411', 'focus-business'],
  ['001412', 'fusion-business'],
  ['001413', 'galaxy-business'],
  ['001414', 'ka-business'],
  ['001415', 'kuga-business'],
  ['001416', 'mondeo-business'],
  ['001418', 's-max-business'],
  ['001420', 'i40'],
  ['001421', 'veloster'],
  ['001424', 'picanto'],
  ['001426', 'voyager'],
  ['001428', 'range-rover-evoque'],
  ['001430', 'bubble'],
  ['001431', 'mp4'],
  ['001434', 'serie-200-280'],
  ['001435', 'classe-cls'],
  ['001436', 'classe-r'],
  ['001437', 'classe-v'],
  ['001438', 'mc1'],
  ['001439', 'mc2'],
  ['001440', 'mini-countryman'],
  ['001441', 'mini-coupe'],
  ['001442', 'mini-roadster'],
  ['001443', 'evalia'],
  ['001444', 'leaf'],
  ['001447', 'ampera'],
  ['001449', 'combo-tour'],
  ['001451', '208'],
  ['001453', 'renault-fluence'],
  ['001455', 'octavia'],
  ['001456', 'xv'],
  ['001457', 'aria'],
  ['001466', 'cc'],
  ['001471', 'alpina-bmw-roadster'],
  ['001472', 'roadster-s'],
  ['001473', 'a3'],
  ['001475', 'a4'],
  ['001476', 'a5'],
  ['001477', 'a6-allroad'],
  ['001478', 'a6'],
  ['001479', 'a8'],
  ['001481', 'venture-trans-sport'],
  ['001483', 'dr-2'],
  ['001484', '458'],
  ['001485', '512i-bb'],
  ['001489', 'carens'],
  ['001490', 'sephia'],
  ['001492', 'x-too-max'],
  ['001493', 'x-too2'],
  ['001494', 'mantula'],
  ['001495', 'serie-e'],
  ['001496', 'terrano'],
  ['001497', '100'],
  ['001498', '200'],
  ['001499', '400'],
  ['001500', '600'],
  ['001501', '800'],
  ['001503', 'musso'],
  ['001505', 'caddy'],
  ['001507', '100-200-5000-v8'],
  ['001508', '80-90-4000-cabrio'],
  ['001509', 'auverland-a2'],
  ['001510', 'z'],
  ['001511', 'cx-5'],
  ['001512', 'c4-aircross'],
  ['001513', 'v40'],
  ['001514', 'punto'],
  ['001515', 'lodgy'],
  ['001516', 'flavia'],
  ['001517', 'mii'],
  ['001518', 'citigo'],
  ['001519', '918-spyder'],
  ['001520', 'b-max'],
  ['001521', 'brz'],
  ['001523', 'gt86'],
  ['001524', '595'],
  ['001525', 'dokker'],
  ['001526', 'f12'],
  ['001527', '500l'],
  ['001528', 'vanquish'],
  ['001529', 'grand-kangoo'],
  ['001530', 'malibu'],
  ['001533', 'xuv500'],
  ['001534', 'melex-943'],
  ['001535', '945'],
  ['001536', 'melex-963'],
  ['001537', 'melex-967'],
  ['001538', 'citan'],
  ['001539', 'mini-paceman'],
  ['001540', 'mustang-mustang'],
  ['001541', 'mokka'],
  ['001542', 'speedster-ii'],
  ['001543', 'v40-cross-country'],
  ['001544', 'ats'],
  ['001545', 'adam'],
  ['001548', 'rapid-s'],
  ['001549', 'flying-spur'],
  ['001551', 'm12'],
  ['001552', 'pick-up'],
  ['001553', 'trax'],
  ['001555', 'voleex-c20r'],
  ['001556', 'q50'],
  ['001557', 'f-type'],
  ['001558', 'classe-cla'],
  ['001559', 'mia'],
  ['001562', 'mini-clubman'],
  ['001563', 'cascada'],
  ['001564', '2008'],
  ['001565', 'captur'],
  ['001566', 'zoe'],
  ['001567', 'rexton-w'],
  ['001569', 'c4-picasso'],
  ['001570', 'city-cross'],
  ['001571', 'grand-c4-picasso'],
  ['001572', 'serie-4'],
  ['001573', 's-cross'],
  ['001575', 'i3'],
  ['001576', '4c'],
  ['001577', 'qx70'],
  ['001578', 'q60'],
  ['001579', 'qx50'],
  ['001584', 'classe-gla'],
  ['001585', 'model-s'],
  ['001586', 'q70'],
  ['001589', 'ecosport'],
  ['001590', 'js50'],
  ['001591', 'm8'],
  ['001593', 'i8'],
  ['001594', 'h6'],
  ['001598', 'aventador'],
  ['001599', 'huracán'],
  ['001600', 'x4'],
  ['001602', 'macan'],
  ['001603', 'casalini-m14'],
  ['001604', 'f8c'],
  ['001605', 'aixam-coupe'],
  ['001607', '108'],
  ['001609', 'c4-cactus'],
  ['001610', '650s'],
  ['001611', 'proace'],
  ['001612', 'laferrari'],
  ['001613', 'tourneo-connect'],
  ['001616', 'tourneo-courier'],
  ['001617', 'up'],
  ['001620', 'nx'],
  ['001621', '695'],
  ['001624', 'ch26'],
  ['001625', 'pulsar'],
  ['001626', 'ch28'],
  ['001627', 'ch32'],
  ['001629', 'xheos'],
  ['001631', 'biro'],
  ['001632', 'em1'],
  ['001634', 'minauto'],
  ['001635', 'discovery-sport'],
  ['001636', 'renegade'],
  ['001637', 'xe'],
  ['001638', 'gt'],
  ['001639', '500x'],
  ['001640', 'wraith'],
  ['001641', 'cinquone'],
  ['001644', 'kizashi'],
  ['001645', 'celerio'],
  ['001646', 'maybach-s'],
  ['001647', 'huayra'],
  ['001649', 'mustang'],
  ['001650', 'vitara'],
  ['001651', 'classe-gle'],
  ['001652', 'zero'],
  ['001653', 'v60-cross-country'],
  ['001654', 'cx-3'],
  ['001655', 'karl'],
  ['001656', 'dr-zero'],
  ['001661', 'serie-1'],
  ['001662', 'serie-2'],
  ['001665', 'kadjar'],
  ['001666', 'ssangyong-tivoli'],
  ['001667', 's60-cross-country'],
  ['001668', 'classe-glc'],
  ['001669', '488'],
  ['001673', 'mgo'],
  ['001674', 'f-pace'],
  ['001675', 'levorg'],
  ['001676', 'q30'],
  ['001677', 'd-truck-400'],
  ['001680', 'tipo'],
  ['001681', 'talisman'],
  ['001682', 'classe-gls'],
  ['001683', 'rc'],
  ['001685', 'evantra'],
  ['001686', 'classe-slc'],
  ['001687', 'fiat-124-spider'],
  ['001688', 'edge'],
  ['001689', 'b8'],
  ['001690', '124-spider'],
  ['001691', 'c-elysee'],
  ['001692', 'optima'],
  ['001693', 'v90'],
  ['001694', 'nomad'],
  ['001695', 's90'],
  ['001696', 'levante'],
  ['001697', 'bentayga'],
  ['001700', 'qx30'],
  ['001702', 'seat-ateca'],
  ['001703', 'db11'],
  ['001704', 'giulia'],
  ['001707', 'xlv'],
  ['001709', 'model-x'],
  ['001710', 'ford-ka-piu'],
  ['001711', '718'],
  ['001714', 'e-mehari'],
  ['001715', 'q2'],
  ['001717', 'spacetourer'],
  ['001718', 'niro'],
  ['001719', 'traveller'],
  ['001720', 'ioniq'],
  ['001722', 'c-hr'],
  ['001726', 'dawn'],
  ['001727', 'grand-scenic'],
  ['001728', 'xt5'],
  ['001730', 'v90-cross-country'],
  ['001731', 'ct6'],
  ['001732', 'due'],
  ['001734', 'kodiaq'],
  ['001735', 'alfa-romeo-stelvio'],
  ['001738', 'gtc4-lusso'],
  ['001739', 'dr-evo5'],
  ['001742', 'range-rover-velar'],
  ['001743', '570s'],
  ['001744', '540c'],
  ['001745', '570gt'],
  ['001747', 'vantage-gt8'],
  ['001748', '720s'],
  ['001749', 'arteon'],
  ['001750', 'lc'],
  ['001753', 'grandland'],
  ['001754', 'e-pace'],
  ['001757', 'tiguan-allspace'],
  ['001759', 'c3-aircross'],
  ['001760', 'seat-arona'],
  ['001763', 't-roc'],
  ['001764', 'stonic'],
  ['001765', 'xc40'],
  ['001766', 'dr4'],
  ['001767', 'dr6'],
  ['001768', 'portofino'],
  ['001769', 'karoq'],
  ['001771', 'kona'],
  ['001772', 'ds7'],
  ['001773', 'x2'],
  ['001774', 'eclipse-cross'],
  ['001775', 'austral'],
  ['001776', 'serie-2-active-tourer'],
  ['001777', 'serie-2-gran-tourer'],
  ['001779', 'zhindou-zd'],
  ['001780', 'serie-3-gran-turismo'],
  ['001782', 'serie-5-gran-turismo'],
  ['001783', 'serie-6-gran-turismo'],
  ['001784', 'serie-4-gran-coupe'],
  ['001785', 'serie-6-gran-coupe'],
  ['001787', 'urus'],
  ['001788', 'i-pace'],
  ['001789', 'stinger'],
  ['001790', 'ch40'],
  ['001791', 'c4-spacetourer'],
  ['001792', 'grand-c4-spacetourer'],
  ['001793', 'kuv100'],
  ['001797', 'rifter'],
  ['001798', 'fatfive'],
  ['001799', 'q8'],
  ['001800', 'scenic'],
  ['001801', '911'],
  ['001803', 'a110'],
  ['001804', 'gt-coupe-4'],
  ['001805', 'ji'],
  ['001806', 'jii'],
  ['001807', 'jiii'],
  ['001809', 'c5-aircross'],
  ['001810', 'ateca'],
  ['001811', 'x7'],
  ['001812', 'ux'],
  ['001813', 'tarraco'],
  ['001815', 'es'],
  ['001816', 'vantage'],
  ['001817', 'e-tron'],
  ['001818', 't-cross'],
  ['001819', 'model-3'],
  ['001820', 'proceed'],
  ['001822', 'dbs-superleggera'],
  ['001823', 'erelis'],
  ['001824', 'skoda-scala'],
  ['001827', 'eqc'],
  ['001828', 'ch46'],
  ['001829', 'f8'],
  ['001830', 'serie-8-gran-coupe'],
  ['001831', 'pulse3'],
  ['001832', 'h2'],
  ['001833', 'cx-30'],
  ['001834', 'taycan'],
  ['001835', 'xceed'],
  ['001836', 'puma'],
  ['001837', 'classe-glb'],
  ['001838', 'mini-full-electric'],
  ['001840', 'kamiq'],
  ['001841', '600lt'],
  ['001844', 'roma-modello'],
  ['001845', 'mirai'],
  ['001846', '812'],
  ['001847', 'sf90-stradale'],
  ['001848', 'iev7s'],
  ['001850', 'mclaren-gt'],
  ['001851', 'senna'],
  ['001853', 'honda-e'],
  ['001854', 'dr-evo'],
  ['001855', 'cullinan'],
  ['001856', 'hero'],
  ['001857', 'ferox'],
  ['001859', 'glory'],
  ['001860', 'explorer'],
  ['001861', 'mx-30'],
  ['001863', 'dr-5-punto-0'],
  ['001865', 'e-niro'],
  ['001866', 'xt4'],
  ['001867', 'g2'],
  ['001868', 'g4'],
  ['001869', 'electric'],
  ['001870', 'fd-34'],
  ['001872', 'evo-4'],
  ['001873', 'evo-5'],
  ['001874', 'evo-6'],
  ['001875', 'dr-f35'],
  ['001876', 'id3'],
  ['001877', 'eqv'],
  ['001878', 'ix3'],
  ['001879', 'formentor'],
  ['001881', 'id4'],
  ['001882', 'mokka-e'],
  ['001883', 'zafira-life'],
  ['001884', 'across'],
  ['001888', 'dbx'],
  ['001890', 'leon'],
  ['001891', 'crossland'],
  ['001892', 'ds-9'],
  ['001893', 'highlander'],
  ['001894', 'yoyo'],
  ['001896', 'kitty'],
  ['001897', 'swace'],
  ['001898', 'enyaq-iv'],
  ['001899', 'ux-full-electric'],
  ['001903', 'eqa'],
  ['001904', 'e-tron-gt'],
  ['001905', 'spring'],
  ['001906', 'arkana'],
  ['001907', 'ami'],
  ['001908', 'elva'],
  ['001909', '620r'],
  ['001910', '3-eleven'],
  ['001911', 'm20'],
  ['001912', '765lt'],
  ['001913', 'ioniq-5'],
  ['001914', 'model-y'],
  ['001916', 'ehs'],
  ['001917', 'zs-ev'],
  ['001919', 'q4'],
  ['001921', 'ev6'],
  ['001922', 'young'],
  ['001924', 'ix'],
  ['001926', 'corsa-e'],
  ['001927', 'opel-combo-life-modello-2'],
  ['001928', 'combo-life'],
  ['001929', 'yaris-cross'],
  ['001930', 'bayon'],
  ['001931', 'c40'],
  ['001933', 'serie-2-gran-coupe'],
  ['001934', 'i4'],
  ['001936', 'evo-3'],
  ['001937', 'evo-3-electric'],
  ['001938', '1'],
  ['001940', 'mature'],
  ['001941', 'd2s'],
  ['001942', 'eqs'],
  ['001943', 'nexo'],
  ['001944', 'mc20'],
  ['001945', 'born'],
  ['001948', 'taigo'],
  ['001949', 'u5'],
  ['001950', 'e-truck'],
  ['001952', 'js60'],
  ['001953', 'dr-4'],
  ['001954', 'id5'],
  ['001955', 'marvel-r'],
  ['001956', 'eqb'],
  ['001957', 'jogger'],
  ['001958', 'minimax'],
  ['001959', 'zero-4'],
  ['001961', 'mazda2-hybrid'],
  ['001963', 'townstar'],
  ['001964', 'artura'],
  ['001966', 'grecale'],
  ['001967', 'cx-60'],
  ['001968', 'eqe'],
  ['001969', 'tonale'],
  ['001971', 'ariya'],
  ['001973', 'dr-6'],
  ['001975', 'i7'],
  ['001976', 'mg5'],
  ['001977', 'toyota-gr86'],
  ['001978', 'classe-t'],
  ['001981', 'glory-500'],
  ['001982', 'glory-500e'],
  ['001983', 'glory-580'],
  ['001984', 'glory-ix5'],
  ['001985', 'solterra'],
  ['001987', 'zero-plus'],
  ['001992', 'idbuzz'],
  ['001993', 'e-soul'],
  ['001996', 'corolla-cross'],
  ['001997', 'alpina'],
  ['002000', 'eqs-suv'],
  ['002001', '408'],
  ['002002', '8c'],
  ['002003', 'ix1'],
  ['002004', 'mg4'],
  ['002005', 'hs'],
  ['002006', 'enyaq-coupe-iv'],
  ['002007', 'bmw-xm'],
  ['002008', 'wave-3'],
  ['002009', 'ioniq-6'],
  ['002057', 'torres'],
  ['002049', 'i5'],
  ['002016', '296'],
  ['002011', 'avenger'],
  ['002050', 'ex30'],
  ['002010', 'ex90'],
  ['001980', 'grenadier'],
  ['002072', 'id.7'],
  ['002025', 'staria'],
  ['002061', 'zr-v'],
  ['002040', 'dr7'],
  ['002047', 'bz4x'],
  ['002032', 'bronco'],
  ['002027', 'dr1-ev'],
  ['002012', 'q8-e-tron'],
  ['002053', 'tivoli'],
  ['002031', '500e'],
  ['002021', 'dr3'],
  ['002013', '#1'],
  ['002042', 'k2'],
  ['001546', 'rapid'],
  ['002066', 'lbx'],
  ['002048', 'rz-full-electric'],
  ['002014', 'purosangue'],
]);
