import { updateFiltersLabelsBulk, type SearchState } from '@reducers/search';
import { TuttaItalia } from '@sbt-web/networking';
import {
  searchGallerized,
  searchGeoboosted,
  searchItems,
} from '@sbt-web/network/client';
import {
  CategoryID,
  HTTPStatusCode,
  NetError,
  SearchParam,
  SearchParams,
} from '@sbt-web/network/types';
import { isEmpty } from '@sbt-web/utils';
import { captureException } from '@sentry/nextjs';
import { ADS_PER_PAGE } from '@shared/constants';
import { DecoratedItem, GalleryItem } from '@shared/models';
import { getRankedItems, RankedItem } from '@shared/helpers/Rank/store';
import {
  afterPlugins,
  beforePlugins,
  ListingPlugin,
  matchCategories,
  shouldAddToListing,
} from '@shared/models/ListingPlugins';
import { all } from '@shared/models/ListingPlugins/matchers';
import { galleryPosition } from '@shared/models/ListingPlugins/position';
import {
  registerError,
  type ErrorSeverity,
  getErrorMessage,
} from '@tools/errorHelpers';
import { providePlugins } from '@tools/listingPlugins';
import type { Response } from 'express';
import { ActionCreator, Reducer } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import type { RootState } from '../';
import type { SubitoAction } from '../common';
import { isSearchEligibleForGeoboost } from '@client/components/Adv/Geoboost/shared';
import { FiltersStore, SPECIAL_FILTERS } from '@tools/search/getFiltersStore';
import { FiltersConfigState } from '@reducers/filtersConfig';

enum Types {
  UPDATE_ITEMS_REQUEST = 'update items request',
  UPDATE_ITEMS_SUCCESS = 'update items success',
  UPDATE_ITEMS_FAILURE = 'update items failure',
}

type ExternalActions = ReturnType<typeof updateFiltersLabelsBulk>;

type UpdateItemsActions =
  | ReturnType<typeof updateItemsFailure>
  | ReturnType<typeof updateItemsRequest>
  | ReturnType<typeof updateItemsSuccess>;

export type ThunkActions = UpdateItemsActions | ExternalActions;

type ItemsActions = UpdateItemsActions;

const defaultState: ItemsState = {
  list: [],
  rankedList: [],
  total: 0,
  totalPages: 0,
  hasError: false,
  loading: false,
};

export const items: Reducer<ItemsState, ItemsActions> = (
  state = defaultState,
  action
): ItemsState => {
  switch (action.type) {
    case Types.UPDATE_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Types.UPDATE_ITEMS_SUCCESS:
      return {
        list: action.payload.list,
        rankedList: action.payload.rankedList,
        total: action.payload.total,
        totalPages: Math.ceil(action.payload.total / ADS_PER_PAGE),
        hasError: false,
        loading: false,
      };
    case Types.UPDATE_ITEMS_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    default:
      return state;
  }
};

export function mapSearchStateToSearchParams(
  searchState: SearchState,
  filtersConfigState: FiltersConfigState
): SearchParams {
  let params: SearchParams = {
    q: searchState.query,
    c:
      searchState.category.id !== CategoryID.Tutte
        ? searchState.category.id
        : undefined,
    r:
      searchState.geo.region.friendlyName !== TuttaItalia.friendlyName
        ? searchState.searchNearRegions
          ? searchState.geo.region.neighbors
          : searchState.geo.region.id
        : undefined,
    ci: searchState.geo?.city?.id,
    to: searchState.geo?.town?.id,
    z: searchState.geo?.zone?.id,
    t: searchState.adType,
    qso: searchState.qso,
    shp: searchState.includeShippableOnly,
    urg: searchState.includeUrgent,
    sort: searchState.category.orders[searchState.orderIndex],
    lim: ADS_PER_PAGE,
    start: getStartingIndex(searchState),
  };

  if (searchState.radiusSearch != undefined) {
    const obj = {
      lat: searchState.radiusSearch.center.lat.toString(),
      lon: searchState.radiusSearch.center.lng.toString(),
      rad: searchState.radiusSearch.radiusMeters.toString(),
    };
    params = { ...params, ...obj };
  }
  const currentConfig = FiltersStore.fromSerialized(
    filtersConfigState.filtersStore
  );

  if (!isEmpty(searchState.filters) && currentConfig) {
    currentConfig.sparseFilters.forEach((filter) => {
      // * special filters are treated already singularly and should not be included in the query anymore
      if (SPECIAL_FILTERS.has(filter.uri)) return;
      const query = filter.queryString as SearchParam;
      if (filter && searchState.filters[query]) {
        params[query] = searchState.filters[query];
      }
    });
  }

  // We want to remove items with no price from the search results if it is sorted by price in ascending order
  if (params.sort === 'priceasc') {
    params.ps = searchState?.filters?.ps ?? '1';
  }

  return params;
}

export const updateItems: ActionCreator<
  ThunkAction<Promise<ThunkActions>, RootState, void, ThunkActions>
> = (
  res?: Response
): ((
  d: ThunkDispatch<RootState, void, ThunkActions>,
  g: () => RootState
) => Promise<ThunkActions>) => {
  return async (dispatch, getState): Promise<ThunkActions> => {
    dispatch(updateItemsRequest());
    try {
      const state = getState();
      const params = mapSearchStateToSearchParams(
        state.search,
        state.filtersConfig
      );
      const environmentId = state.env.id || undefined;

      const itemsPromise = searchItems(
        process.env.ANUBI_BASE_URL || process.env.NEXT_PUBLIC_HADES_BASE_URL,
        params,
        environmentId,
        true
      );

      const galleryPromise = searchGallerized(
        process.env.ANUBI_BASE_URL || process.env.NEXT_PUBLIC_HADES_BASE_URL,
        { ...params, [SearchParam.PageSize]: 3 },
        environmentId,
        true
      );

      const geoboostPromise = isSearchEligibleForGeoboost(state.search)
        ? searchGeoboosted(
            process.env.ANUBI_BASE_URL ||
              process.env.NEXT_PUBLIC_HADES_BASE_URL,
            { ...params, [SearchParam.PageSize]: 5 },
            environmentId,
            true
          ).then((res) => res.body.ads)
        : Promise.resolve([]);

      let searchResponse;
      try {
        searchResponse = await itemsPromise;
      } catch (e) {
        if (res) {
          handleInvalidResponse(res, 'FATAL', e);
        }
        return dispatch(updateItemsFailure());
      }
      const items = searchResponse.body;

      if (__SERVER__ && items.filterLabels) {
        dispatch(updateFiltersLabelsBulk(items.filterLabels));
      }

      const galleryResponse = await galleryPromise;
      const galleryList = galleryResponse.body.ads;
      const pageGalleryCount = galleryList.length;
      const pageAdsCount = items.ads.length;

      const galleryPlugins = galleryList.map(
        (item): ListingPlugin => ({
          model: new GalleryItem(item),
          position: galleryPosition(),
          categories: all(),
        })
      );

      const boostedItems = await geoboostPromise;

      const validPlugins = flatMap(providePlugins(), (p: ListingPlugin) => {
        if (p.model.kind === 'GalleryPlaceholder') {
          return galleryPlugins;
        } else if (p.model.kind === 'GeoboostItem') {
          p.model.boostedItems = boostedItems;
          return [p];
        } else {
          return [p];
        }
      })
        .filter(shouldAddToListing(pageAdsCount, pageGalleryCount, items.total))
        .filter(matchCategories(state.search.category.id));

      const rankedList: Array<RankedItem> = [];
      const decoratedItems = items.ads.map((item, itemIndex) => {
        const before = validPlugins.filter(
          beforePlugins(pageAdsCount, itemIndex)
        );
        const after = validPlugins.filter(
          afterPlugins(pageAdsCount, itemIndex)
        );

        rankedList.push(...getRankedItems([...before, item, ...after]));
        return new DecoratedItem(before, item, after);
      });

      return dispatch(
        updateItemsSuccess({
          list: decoratedItems,
          rankedList,
          total: items.total,
        })
      );
    } catch (error) {
      captureException(`Failed to call Anubi: ${getErrorMessage(error)}`, {
        level: 'fatal',
      });

      return dispatch(updateItemsFailure());
    }
  };
};

export function getStartingIndex(searchState: SearchState): number | undefined {
  return searchState.page > 0 ? (searchState.page - 1) * ADS_PER_PAGE : 0;
}

function handleInvalidResponse(
  res: Response,
  severity: ErrorSeverity,
  e: unknown
): void {
  let calculatedStatus: HTTPStatusCode = 500;
  let info;
  let errors;
  if (e instanceof NetError) {
    calculatedStatus = e.cause.status;
    info = e.message;
    errors = e.cause.errors;
  } else if (typeof e === 'string' && e.includes('aborted')) {
    calculatedStatus = HTTPStatusCode.GatewayTimeout;
  }
  registerError(res, calculatedStatus, severity, {
    errors,
    info,
    service: 'ANUBI',
    message: new Error('Request failed to Anubi.'),
  });
}

interface SuccessPayload {
  list: DecoratedItem[];
  rankedList: RankedItem[];
  total: number;
}

export function updateItemsSuccess(
  payload: SuccessPayload
): SubitoAction<Types.UPDATE_ITEMS_SUCCESS, SuccessPayload> {
  return { type: Types.UPDATE_ITEMS_SUCCESS, payload };
}

export function updateItemsRequest(): SubitoAction<Types.UPDATE_ITEMS_REQUEST> {
  return { type: Types.UPDATE_ITEMS_REQUEST, payload: undefined };
}

export function updateItemsFailure(): SubitoAction<Types.UPDATE_ITEMS_FAILURE> {
  return { type: Types.UPDATE_ITEMS_FAILURE, payload: undefined };
}

// T: Array of (usually) array, Z: target type, f: Maps T to Z[]
function flatMap<T, Z>(arr: T[], f: (e: T) => Z[]): Z[] {
  const concat = (x: Z[], y: Z[]): Z[] => x.concat(y);
  return arr.map(f).reduce(concat, []);
}

interface ItemsState {
  list: DecoratedItem[];
  rankedList: RankedItem[];
  total: number;
  totalPages: number;
  hasError: boolean;
  loading: boolean;
}

export type { ItemsState };
