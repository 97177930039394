import { captureException } from '@sentry/nextjs';
import {
  type SerializableFilter,
  type CompositeFilter,
  type CategoryID,
  type AdTypes,
  FilterURI,
} from '@sbt-web/network/types';
import { getFiltersConfig } from '@sbt-web/network/client';

const configCache: Map<string, Promise<FiltersStore | null>> = new Map();

// Long timeout because it's a static endpoint and it should be a very fast response
// the only reason it could be killed by timeout is JS single thread to be busy
const FILTER_STORE_TIMEOUT = 15000;

/**
 * Retrieves the filters store for a given category and ad type.
 *
 * This function first checks if the filters store for the specific category and ad type
 * combination is already cached. If it is, it returns the cached value. If not, it fetches
 * the filters configuration from an external service, processes the response to create a
 * `FiltersStore` instance, caches it, and then returns it.
 *
 * @param category - The category for which to retrieve the filters store.
 * @param adType - The ad type for which to retrieve the filters store.
 * @returns A promise that resolves to a `FiltersStore` instance or `null` if an error occurs.
 */
const getFiltersStore = async (
  category: CategoryID,
  adType: AdTypes
): Promise<FiltersStore | null> => {
  // Edge case: if either category or adType is falsy, return null
  if (!category || !adType) return null;

  const specificCategory = `${category}_${adType}`;

  if (configCache.has(specificCategory)) {
    return configCache.get(specificCategory)!;
  } else {
    const filterConfigResponse = getFiltersConfig(
      process.env.NEXT_PUBLIC_HADES_BASE_URL,
      category,
      adType,
      FILTER_STORE_TIMEOUT,
      true
    );

    const categoryFilters = filterConfigResponse
      .then((response) => response.body.filters)
      .then((filters) => new FiltersStore(filters))
      .catch((e) => {
        captureException(
          new Error(
            `Could not retrieve the filters config for ${specificCategory}`
          ),
          {
            level: 'error',
            extra: {
              originalError: e,
              invoker: 'newFiltersCall',
            },
          }
        );
        configCache.delete(specificCategory);
        // return null if there is an error
        return null;
      });

    configCache.set(specificCategory, categoryFilters);

    return categoryFilters;
  }
};

/**
 * The `FiltersStore` class is responsible for managing and storing filters.
 * It provides methods to find filters by their query string or URI.
 */
class FiltersStore {
  readonly filters: CompositeFilter[];
  readonly sparseFilters: SerializableFilter[];
  private readonly mappedByQS: Map<string, SerializableFilter>;
  private readonly mappedByURI: Map<string, SerializableFilter>;

  constructor(
    filters: CompositeFilter[],
    sparseFilters?: SerializableFilter[]
  ) {
    this.filters = filters;
    this.sparseFilters =
      sparseFilters ??
      this.filters.flatMap((filter) => {
        if (filter.type === 'range') {
          return [filter.min, filter.max];
        }
        return [filter];
      });
    this.mappedByQS = new Map(
      this.sparseFilters.map((filter) => [filter.queryString, filter])
    );
    this.mappedByURI = new Map(
      this.sparseFilters.map((filter) => [filter.uri, filter])
    );
  }

  findByFilterQueryString(shortName: string): SerializableFilter | undefined {
    return this.mappedByQS.get(shortName);
  }

  findByFilterURI(uri: string): SerializableFilter | undefined {
    return this.mappedByURI.get(uri);
  }

  static fromSerialized(
    filterStore: FiltersStore | SerializedFiltersStore | null
  ): FiltersStore | null {
    if (!filterStore) return null;
    if (filterStore instanceof FiltersStore) return filterStore;
    return new FiltersStore(filterStore.filters, filterStore.sparseFilters);
  }
}

type SerializedFiltersStore = {
  filters: FiltersStore['filters'];
  sparseFilters?: FiltersStore['sparseFilters'];
};

const SPECIAL_FILTERS = new Set<string>([
  FilterURI.AdType,
  FilterURI.ItemShippable,
  FilterURI.ItemUrgent,
  FilterURI.QuerySubjectOnly,
]);

export { getFiltersStore, FiltersStore, SPECIAL_FILTERS };
