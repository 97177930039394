import {
  BestBuyItem,
  ExpandSearchItem,
  GalleryCTAItem,
  GalleryPlaceholder,
  GeoboostItem,
  ListingPlugin,
} from '@shared/models';
import { all, include } from '@shared/models/ListingPlugins/matchers';
import {
  after,
  endOfListing,
  galleryPosition,
} from '@shared/models/ListingPlugins/position';
import { desktopAdvEvery3 } from '@tools/listingPlugins/desktopAdvEvery3';
import { msiteAdvEvery3 } from '@tools/listingPlugins/msiteAdvEvery3';
import { GEOBOOST_CATEGORIES } from '@client/components/Adv/Geoboost/shared';
import {
  BEST_BUY_CATEGORIES,
  BEST_BUY_LISTING_POSITION,
} from '@client/components/Adv/BestBuy/shared';

const plugins: ListingPlugin[] = [
  {
    model: new GalleryPlaceholder(),
    categories: all(),
    position: galleryPosition(),
  },
  {
    model: new GeoboostItem(),
    categories: include(...GEOBOOST_CATEGORIES),
    position: galleryPosition(),
  },
  {
    model: new GalleryCTAItem(),
    categories: all(),
    position: galleryPosition(),
    addToListing: (_, galleryCount) => galleryCount > 0,
  },
  {
    model: new BestBuyItem(),
    categories: include(...BEST_BUY_CATEGORIES),
    position: after(BEST_BUY_LISTING_POSITION),
  },
  {
    model: new ExpandSearchItem(),
    categories: all(),
    position: endOfListing(),
    addToListing: (_, __, searchAdsCount) => searchAdsCount < 5,
  },
];

/**
 * Function providing an array containing all the plugins that should be added to the listing.
 */
export const providePlugins = (): ListingPlugin[] => [
  ...plugins,
  // new setup "ADV every 3" for desktop
  ...desktopAdvEvery3,
  // new setup "ADV every 3" for msite
  ...msiteAdvEvery3,
];
