import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import classes from './index.module.scss';

interface ToggleDetailsProps extends React.HTMLAttributes<HTMLDetailsElement> {
  summary: React.ReactNode;
  children: React.ReactNode;
  onDetailsToggle?: (isOpen: boolean) => void;
  className?: string;
  detailsTestId?: string;
  summaryTestId?: string;
}

export function ToggleDetails({
  summary,
  children,
  onDetailsToggle,
  className,
  detailsTestId,
  summaryTestId,
  ...rest
}: Readonly<ToggleDetailsProps>) {
  const detailsRef = useRef<HTMLDetailsElement | null>(null);

  useEffect(() => {
    const detailsElement = detailsRef.current;

    if (!detailsElement) return;

    const handleToggle = () =>
      onDetailsToggle ? onDetailsToggle(detailsElement.open) : null;

    detailsElement.addEventListener('toggle', handleToggle);

    return () => {
      detailsElement.removeEventListener('toggle', handleToggle);
    };
  }, [onDetailsToggle]);

  return (
    <details
      ref={detailsRef}
      className={cn(classes['details'], className)}
      data-testid={detailsTestId}
      {...rest}
    >
      <summary className={classes['summary']} data-testid={summaryTestId}>
        {summary}

        <img
          src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/cactus/arrow-down.svg`}
          className={classes['arrow-icon']}
          loading="lazy"
          alt=""
        />
      </summary>

      <div className={classes['content']}>{children}</div>
    </details>
  );
}
