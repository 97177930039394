import { ListingPlugin, SmartADVItem } from '@shared/models';
import { all, exclude, include } from '@shared/models/ListingPlugins/matchers';
import { after } from '@shared/models/ListingPlugins/position';
import {
  ADSENSE_DESKTOP_STYLE,
  GAM_DESKTOP_STYLE,
  CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE,
} from './commons';
import { LiraContainerIds } from '@client/components/Adv/Lira/liraContainerIds';

const desktopAdvEvery3: Array<ListingPlugin> = [
  // --- After the first Ad
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        style: ADSENSE_DESKTOP_STYLE,
        id: LiraContainerIds.listing.aboveListing,
      },
      null
    ),
    categories: all(),
    position: after(1),
  },
  // --- Then after two Ads
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        id: LiraContainerIds.listing.native1,
        // This placement near the fold has 200px to reduce the CLS with native
        // placement (retail media) or with big cards.
        style: { ...GAM_DESKTOP_STYLE, minHeight: '200px' },
      },
      null
    ),
    categories: all(),
    position: after(3),
  },
  // --- Then every 3 Ads
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        id: LiraContainerIds.listing.middle1,
        // In many verticals this placement has AdSense for shopping enabled.
        // So the block can take from 200px to 360px.
        style: ADSENSE_DESKTOP_STYLE,
        //variations: DesktopMiddle1,
        //gamFallback: DesktopGAMIds.FALLBACKMIDDLE1,
      },
      null
    ),
    categories: all(),
    position: after(6),
  },
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        style: GAM_DESKTOP_STYLE,
        id: LiraContainerIds.listing.native2,
      },
      null
    ),
    categories: all(),
    position: after(9),
  },
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        // In many verticals this placement has AdSense for shopping enabled.
        // So the block can take from 200px to 360px.
        style: ADSENSE_DESKTOP_STYLE,
        id: LiraContainerIds.listing.middle2,
        //variations: DesktopMiddle2,
        //gamFallback: DesktopGAMIds.FALLBACKMIDDLE2,
      },
      null
    ),
    categories: all(),
    position: after(12),
  },

  //Position 15:
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        id: LiraContainerIds.listing.middle3,
        //variations: DesktopMiddle3,
        //gamFallback: DesktopGAMIds.NATIVE3,
      },
      null
    ),
    categories: exclude(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(15),
  },
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        style: GAM_DESKTOP_STYLE,
        id: LiraContainerIds.listing.native3,
      },
      null
    ),
    categories: include(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(15),
  },

  //Position 18:
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        id: LiraContainerIds.listing.middle4,
        //variations: DesktopMiddle4,
        //gamFallback: DesktopGAMIds.NATIVE4,
      },
      null
    ),
    categories: exclude(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(18),
  },
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        style: GAM_DESKTOP_STYLE,
        id: LiraContainerIds.listing.native4,
      },
      null
    ),
    categories: include(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(18),
  },

  //Position 21:
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        //variations: DesktopMiddle5,
        //gamFallback: DesktopGAMIds.FALLBACKMIDDLE3,
        id: LiraContainerIds.listing.middle5,
      },
      null
    ),
    categories: all(),
    position: after(21),
  },

  //Position 24:
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        id: LiraContainerIds.listing.middle6,
        //variations: DesktopMiddle6,
        //gamFallback: DesktopGAMIds.NATIVE5,
      },
      null
    ),
    categories: exclude(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(24),
  },
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        style: GAM_DESKTOP_STYLE,
        id: LiraContainerIds.listing.native5,
      },
      null
    ),
    categories: include(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(24),
  },

  //Position 27:
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        //variations: DesktopMiddle7,
        //gamFallback: DesktopGAMIds.NATIVE6,
        id: LiraContainerIds.listing.middle7,
      },
      null
    ),
    categories: exclude(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(27),
  },
  {
    model: new SmartADVItem(
      {
        source: 'lira',
        style: GAM_DESKTOP_STYLE,
        id: LiraContainerIds.listing.native6,
      },
      null
    ),
    categories: include(...CAT_GAM_INSTEAD_OF_ADSENSE_FOR_NATIVE),
    position: after(27),
  },
];

export { desktopAdvEvery3 };
