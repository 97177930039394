import { CategoryId, CategoryStore } from '@sbt-web/networking';
import { BodyText, Subhead } from '@sbt-web/ui';
import { adTypesToSlugMap } from '@shared/tools/search/values';
import { ToggleDetails } from '@client/components/Search/Elements/ToggleDetails';
import React, { type FunctionComponent } from 'react';
import ownClasses from './Sitemap.module.scss';

const macrosToShow = CategoryStore.macroCategories.filter(
  (macro) => macro.id !== CategoryId.Altri && macro.id !== CategoryId.Tutte
);

const categoryLists = macrosToShow.map((macro) => (
  <div key={macro.id} className={ownClasses.macro}>
    <Subhead
      size="small"
      classes={[
        ownClasses.title,
        ownClasses.capitalize,
        ownClasses['category-name'],
      ]}
      element="p"
    >
      <a href={`/annunci-italia/vendita/${macro.friendly}/`}>
        {macro.label.toLowerCase()}
      </a>
    </Subhead>

    <ol className={ownClasses['sub-list']}>
      {macro.categories.map((category) => (
        <li key={category.id} className={ownClasses['category']}>
          <BodyText size="small" classes={[ownClasses['category-name']]}>
            <a
              href={`/annunci-italia/${
                adTypesToSlugMap[category.defaultAdType]
              }/${category.friendly}/`}
            >
              {category.label}
            </a>
          </BodyText>
        </li>
      ))}
    </ol>
  </div>
));

const Sitemap: FunctionComponent = () => {
  return (
    <section data-google-interstitial="false">
      <ToggleDetails
        summary={<h2>Tutte le categorie</h2>}
        className={ownClasses['container']}
      >
        <div className={ownClasses['categories-list']}>{categoryLists}</div>
      </ToggleDetails>
    </section>
  );
};

export default Sitemap;
