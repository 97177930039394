import { CategoryID } from '@sbt-web/network/types';
import { Item } from '../Item';
import { InclusionMatcher, matches } from './matchers';
import { Position } from './position';

/**
 * A listing plugin is a  **custom item** that can be plugged in the middle of the ads listing.
 */

/**
 * An interface defining a plugin
 */
export interface ListingPlugin {
  /**
   * Used to decide the position of the plugins within ads.
   * It takes into account only "normal" ads (no gallery) and it numbers ads starting from 1.
   */
  position: Position;
  /**
   * Matcheer used to decide if the plugin should be added depending on the category.
   */
  categories: InclusionMatcher<CategoryID>;
  /**
   * Define the model of the plugin.
   */
  model: Item;
  /**
   * Optional function used to add extra conditions to addition of the plugin.
   * If it is not provided the plugin will be added if there are enough ads to reach the position
   * defined with [[after]].
   */
  addToListing?: (
    pageAdsCount: number,
    pageGalleryCount: number,
    searchAdsCount: number
  ) => boolean;
}

type FnPluginToBool = (pl: ListingPlugin) => boolean;

export const shouldAddToListing =
  (
    pageAdsCount: number,
    pageGalleryCount: number,
    searchAdsCount: number
  ): FnPluginToBool =>
  (pl: ListingPlugin) =>
    pl.addToListing
      ? pl.addToListing(pageAdsCount, pageGalleryCount, searchAdsCount)
      : true;

export const matchCategories =
  (categoryId: CategoryID): FnPluginToBool =>
  (pl: ListingPlugin) =>
    matches(pl.categories, categoryId);

export const beforePlugins =
  (pageAdsCount: number, index: number): FnPluginToBool =>
  (pl: ListingPlugin) =>
    pl.position.computeValue(pageAdsCount) === index + 1 &&
    pl.position.kind === 'BeforePosition';

export const afterPlugins =
  (pageAdsCount: number, index: number): FnPluginToBool =>
  (pl: ListingPlugin) =>
    pl.position.computeValue(pageAdsCount) === index + 1 &&
    pl.position.kind === 'AfterPosition';
