import type { DLCategory } from '@client/bridges/DataLayer';
import { CategoryID, type ItemCategory } from '@sbt-web/network/types';
import { slugify } from '@sbt-web/utils';
import { getCategory } from '@sbt-web/tracking';

const isItemCategory = (
  category: DLCategory | ItemCategory
): category is ItemCategory => 'id' in category;

export default function transform(category: DLCategory | ItemCategory): string {
  if (isItemCategory(category)) {
    return getCategory(category.id) ?? '';
  }

  const { parent, child } = category;
  if (parent.id === CategoryID.Tutte) {
    return '';
  } else if (parent.id === CategoryID.Altri) {
    return `altro>${slugify(child.name)}`;
  } else if (parent.id === child.id) {
    return slugify(parent.name);
  }

  return `${slugify(parent.name)}>${slugify(child.name)}`;
}
