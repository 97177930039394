import { type AdItem, AdTypes, CategoryID } from '@sbt-web/network/types';
import {
  GeoEntry,
  HebeCampaignPage,
  HebeCampaignPosition,
  HebeCampaignProperty,
} from '@sbt-web/networking';
import {
  parseAdDetailFeaturesForHebe,
  parseListingFiltersForHebe,
} from '@tools/search/hebeFiltersParser';
import { RemoteData } from '@sbt-web/ancillary-services';
import { HADES_PATH } from '@shared/constants';
import type { FiltersStore } from '@tools/search/getFiltersStore';

export const prepareRemoteDataAdDetail = (
  position: HebeCampaignPosition,
  item: AdItem,
  isMobile: boolean
): RemoteData => ({
  baseUrl: HADES_PATH,
  body: {
    touchpoint: {
      page: HebeCampaignPage.ADDETAIL,
      position,
    },
    metadata: {
      ad_type: item.type.key,
      advertiser_id: item.advertiser?.userId,
      categories: [item.category.id],
      geo: {
        region: item.geo?.region?.id,
        city: item.geo?.city?.id,
        town: item.geo?.town?.id,
      },
      keywords: item.subject,
      property: isMobile
        ? HebeCampaignProperty.MSITE
        : HebeCampaignProperty.WEB,
    },
    filters: parseAdDetailFeaturesForHebe(item),
  },
});

export const prepareRemoteDataListing = (
  position: HebeCampaignPosition,
  ad_type: AdTypes,
  category: CategoryID,
  geo: GeoEntry,
  filters: Record<string, string>,
  query: string,
  isMobile: boolean,
  filterStore: FiltersStore | null
): RemoteData => ({
  baseUrl: HADES_PATH,
  body: {
    touchpoint: {
      page: HebeCampaignPage.LISTING,
      position,
    },
    metadata: {
      ad_type,
      categories: [category],
      geo: {
        ...(geo?.region?.id !== '0' && { region: geo?.region?.id }),
        city: geo?.city?.id,
        town: geo?.town?.id,
      },
      keywords: query,
      property: isMobile
        ? HebeCampaignProperty.MSITE
        : HebeCampaignProperty.WEB,
    },
    filters: parseListingFiltersForHebe(
      category,
      ad_type,
      filters,
      filterStore
    ),
  },
});
